import React from 'react'

export default function HelloBox() {
  return (
    <div className="helloBox">
    
    <h1>HelloBox</h1>

    Hellow this is the hellow box



    </div>
  )
}
